export const ApplicationPaths = {
    LOGIN: "/login",
    HOME: "/home",
    MANAGE_USERS: "/users",
    ORDERS: "/orders",
    MANAGE_RESOURCES: "/resources",
    USER_EDIT: "/edit-user",
    RESTRICTED_PATH: '/restricted'
}

export const ToolbarHeight = "64px"

export const shinpadSizes = ["XXXXS", "XXXS", "XXS", "XS", "S", "M", "L"];
export const carbonSizes = ["XS", "S", "M", "L"];
export const shinpadOptionals = ["blancas", "negras", "no"];

export enum Application_PROFILE {
    PROFILE_ADMIN = 'ADMIN',
    PROFILE_SHOP = 'SHOP'
};

export const Materials = {
    carbon: {
        id: "8554",
        name: "CARBON",
        translation: "product.material.carbon"
    },
    flex: {
        id: "46511",
        name: "FLEX",
        translation: "product.material.flex"
    },
    polimeric: {
        id: "5112",
        name: "POLIMERIC",
        translation: "product.material.normal"
    },
}

//Key: material id, value: fields name
export const MaterialFields = {
    size: process.env.REACT_APP_WOOCOMMERCE_SIZE,
    preview: process.env.REACT_APP_WOOCOMMERCE_PREVIEW,
    left: process.env.REACT_APP_WOOCOMMERCE_LEFT,
    right: process.env.REACT_APP_WOOCOMMERCE_RIGHT,
    extra: process.env.REACT_APP_WOOCOMMERCE_EXTRA,
}